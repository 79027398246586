<template>
  <div class="page-content">
    <b-row>
      <b-col md="10">
        <page-breadcrumb title="Product Management" class="mb-3" />
      </b-col>
    </b-row>
    <data-create-popup title="Create Product"
      :schema="create_schema" :default-data="{thumbnail: null, name: null, type: null}"
      :create-data-fn="createItem" @create="$refs.group_list.getList()"
    />
    <data-table-ssr id="group_list" ref="group_list"
      :columns="fields" :get-list-fn="getList" :to-edit-fn="toEditPage"
    />
  </div>
</template>

<script>
import service from "../service";
const create_schema = [
  {
    cols: 4,
    fields: [
      { label: 'Thumbnail', field: 'thumbnail', input_type: 'image' },
    ]
  },
  {
    cols: 8,
    fields: [
      { label: 'Store Id', field: 'store_id', validate: { required: true } },
    ]
  }
];
const fields = [
  { label: 'Thumbnail', field: 'thumbnail', input_type: 'image' },
  { label: 'Store Id', field: 'store_id' },
  { label: 'Product Info', field: 'product', value_handler: (model)=>{
    let str = '';
    model.products.forEach((product)=>{
      str += `${product.product_id.name}: ${product.quantity} \n`
    });
    return str;
  }, styleMethod: ()=>{return {'white-space': 'pre'}} },
];
export default {

  data() {
    return {
      courses: [],
      create_schema,
      fields,
    };
  },

  created() {
  },

  methods: {
    async getList({ limit, page, query }) {
      let response_data = await service.getList({
        query: JSON.stringify({ ...query }),
        page, limit,
        populate: {path: 'products.product_id'}
      });
      let list = [], total = 0;
      if (response_data) {
        list = response_data.list;
        total = response_data.total;
        this.total = total;
      }
      return { list, total };
    },
    async createItem(data) {
      let new_item = await service.create({ ...data });
      return new_item;
    },
    toEditPage({ _id }) {
      this.$router.push({ name: 'iap-product-detail', params: { id: _id } });
    },
  },
};
</script>
